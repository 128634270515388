import validate from "C:/agent-gibs/_work/4/s/frontend/web/node_modules/nuxt/dist/pages/runtime/validate.js";
import cors_45global from "C:/agent-gibs/_work/4/s/frontend/web/src/middleware/cors.global.ts";
import manifest_45route_45rule from "C:/agent-gibs/_work/4/s/frontend/web/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  cors_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "cache-control": () => import("C:/agent-gibs/_work/4/s/frontend/web/src/middleware/cache-control.ts")
}