<template>
    <div id="gibs-full-page" class="w-full invisible">
        <GibsControlLoading ref="loadingRef" @LoadingStateChange="LoadingStateChange" />
        <header id="gibs-header" class="gibs-header">

            <template v-if="layoutView.view !== 'mobile'">
                <nav class="gibs-header-nav">
                    <div class="flex flex-wrap items-center justify-between 
                                                    max-w-screen-1xl
                                                    mx-auto p-0">
                        <div class="gibs-header-nav-logo">
                            <NuxtLink id="gibs-logo-header" class="gibs-logo-header" to="/">
                                <nuxt-icon name="logo" />
                            </NuxtLink>

                            <div class="gibs-course-finder-button-container">
                                <GibsControlCourseFinderButton :courseFinderButtonSpec="courseFinderSpec" />
                            </div>

                            <div class="flex flex-row">
                                <ul id="gibs-secondary-menu" class="flex">
                                    <li v-for="item in navItems" :key="item.name"
                                        class="gibs-header-nav-mainmenu-item gibs-header-nav-menu-item sec-menu-items">
                                        <NuxtLink
                                            v-if="!item.items && !isMobileSecondaryOpen && navItems.indexOf(item) < navItems.length - 1"
                                            :to="item.url" :target="item.target" class="block py-2"
                                            :external="item.target === '_blank' ? true : false"
                                            @click="toggleDesktopSecMenuStyling(false)">
                                            <span class="menu-items">{{ item.name }} </span>
                                            <span class="pipe-separator">|</span>
                                        </NuxtLink>
                                        <NuxtLink
                                            v-else-if="!item.items && (!isMobileSecondaryOpen || navItems.indexOf(item) < navItems.length - 1)"
                                            :to="item.url" :target="item.target" class="block py-2"
                                            :external="item.target === '_blank' ? true : false">
                                            <span class="menu-items">{{ item.name }} </span>
                                        </NuxtLink>
                                        <div v-else class="relative">
                                            <button @click="toggleDesktopSecMenu"
                                                :id="'desktop-dropdownButton-' + item.name" type="button"
                                                class="desktop-dropbutton">
                                                {{ item.name }}
                                                <nuxt-icon :name="(!isDesktopSecondaryOpen) ? 'Dropdown' : 'DropdownUp'"
                                                    :id="'desktop-dropdownButton-' + item.name + '-icon'"
                                                    class="text-[10px] h-[10px] w-[10px] relative top-[5px] ml-1 mt-0.75" />
                                                <span v-if="navItems.indexOf(item) < navItems.length - 1"
                                                    class="pipe-separator">|</span>
                                            </button>
                                            <ul :id="'desktop-secondary-menu-draw-' + item.name"
                                                class="secondaryNavDropdown"
                                                :class="['dropDownItem', isMobileSecondaryOpen ? '' : 'hidden']">
                                                <li>
                                                    <h3> {{ item.name }}</h3>
                                                    <div class="sub-dropdown-separator"></div>
                                                </li>
                                                <li v-for="(subItem, subIndex) in item.items" :key="subItem.name"
                                                    class="gibs-header-nav-mainmenu-item gibs-header-nav-menu-item"
                                                    :id="'gibs-header-nav-mainmenu-item-' + subIndex">
                                                    <NuxtLink :to="subItem.url" :external="subItem.target === '_blank' ? true : false" @click="toggleDesktopSecMenu"
                                                        class="block py-2 pr-4">
                                                        {{ subItem.name }}
                                                    </NuxtLink>
                                                </li>
                                            </ul>
                                        </div>
                                    </li>
                                </ul>
                                <NuxtLink to="/search" id="searchButton" class="search-icon">
                                    <NuxtIcon name="search-icon" />
                                </NuxtLink>
                            </div>
                        </div>
                        <div id="gibs-main-menu" class="gibs-header-nav-mainmenu">
                            <ul id="gibs-main-menu" class="gibs-main-menu">
                                <template v-for="mainMenuItem in mainNavigationItems">
                                    <li class="gibs-header-nav-mainmenu-item gibs-header-nav-menu-item">
                                        <NuxtLink class="" :to="mainMenuItem.URL" :target="mainMenuItem.Target" @click="toggleDesktopSecMenuStyling(false)">
                                            {{ mainMenuItem.Name }}
                                        </NuxtLink>
                                    </li>
                                </template>
                            </ul>
                            <div class="relative max-w-[450px] w-full">
                                <div class="gibs-header-nav-mainmenu-profile !w-full">
                                    <template v-if="computedProfileLoggedIn">
                                        <div @click="toggleProfileDropdown(false)" class="gibs-user-menu-wrapper">
                                            <span class="gibs-profile-user-name">{{ computedProfileName }}</span>
                                            <div class="gibs-profile-icon">
                                                <nuxt-icon name="gibs-profile" />
                                            </div>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div @click="toggleLoginForm" class="gibs-user-menu-wrapper">
                                            <span>LOGIN / SIGNUP</span>
                                            <div class="gibs-profile-icon">
                                                <nuxt-icon name="gibs-profile" />
                                            </div>
                                        </div>
                                    </template>
                                </div>
                                <Transition name="modal">
                                    <div v-if="showLoginForm">
                                        <div id="login-modal" class="login-modal">
                                            <div class="gibs-close-modal-btn-container">
                                                <button class="gibs-close-modal-container" @click="toggleLoginForm">
                                                    <nuxt-icon class="login-modal-close-btn" name="buttons/close-cross" />
                                                </button>
                                            </div>
                                            <!-- TODO: Refactor needed. use enum -->
                                            <div v-if="!showForgotPasswordForm && !showEmailSentForm">
                                                <div class="login-tabContent">
                                                    <GibsLoginForm :componentId="loginFormComponentId"
                                                        @successLogin="closeModalLogin" />
                                                </div>
                                            </div>
                                            <div v-else-if="showForgotPasswordForm && !showEmailSentForm">
                                                <div class="forgot-password">
                                                    <GibsForgotPassword :componentId="forgotPasswordFormComponentId"
                                                        @toggleResetEmailSentForm="toggleResetEmailSentForm" />
                                                </div>
                                            </div>
                                            <div v-else-if="showEmailSentForm">
                                                <div class="forgot-password">
                                                    <GibsPasswordResetEmail :componentId="emailSentFormComponentId"
                                                        @closeModalAndShowLogin="closeModalAndShowLogin" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                                <Transition name="modal">
                                    <div v-if="showProfileDropdown">
                                        <div id="gibs-profile-menu-dropdown" class="gibs-profile-menu-dropdown">
                                            <div class="gibs-close-modal-btn-container">
                                                <button class="gibs-close-profile-menu" @click="toggleProfileDropdown(false)">
                                                    <nuxt-icon class="profile-menu-close-btn" name="buttons/close-cross" />
                                                </button>
                                            </div>
                                            <div class="gibs-profile-menu-container">
                                                <ul id="gibs-profile-menu" class="gibs-profile-menu">
                                                    <li class="gibs-profile-menu-title">
                                                        <p> My GIBS Profile </p>
                                                        <div class="sub-dropdown-separator"></div>
                                                    </li>
                                                    <li class="gibs-profile-menu-item">
                                                        <GibsControlTextLink :textLinkSpec="myLearningsLink"
                                                            @click="toggleProfileDropdown(true)" />
                                                    </li>
                                                    <li class="gibs-profile-menu-item">
                                                        <GibsControlTextLink :textLinkSpec="profileLink"
                                                            @click="toggleProfileDropdown(true)" />
                                                    </li>
                                                    <li class="gibs-profile-menu-item">
                                                        <GibsControlTextLink :textLinkSpec="changePasswordLink"
                                                            @click="toggleProfileDropdown(true)" />
                                                    </li>
                                                    <li class="gibs-profile-menu-signout">
                                                        <div class="sub-dropdown-separator"></div>
                                                        <GibsControlTextLinkLogo :textLinkLogoSpec="signOutLink"
                                                            @click="signOutProfile" />
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </Transition>
                            </div>
                        </div>
                    </div>
                </nav>
            </template>

            <template v-else-if="layoutView.view === 'mobile'">
                <nav class="gibs-header-nav">
                    <div class="flex flex-wrap items-center justify-center bg-catalinablue
                                max-w-screen
                                mx-auto p-0">
                        <div class="gibs-header-nav-logo">
                            <NuxtLink id="gibs-logo-header" class="gibs-logo-header" to="/" @click="closeMobileMenu">
                                <nuxt-icon name="logo" />
                            </NuxtLink>
                            <div class="gibs-hamburger-nav">
                                <button @click="toggleMobileMenu" id="gibs-navbar-hamburger-menu"
                                    class="gibs-navbar-hamburger-menu" type="button">
                                    <span>menu</span>
                                    <div class="gibs-menu-header">
                                        <Transition name="menu">
                                            <nuxt-icon class="absolute" v-if="!showMobileMenu" :name="'gibs-hamburger'" />
                                            <nuxt-icon class="relative" v-else :name="'gibs-close'" />
                                        </Transition>
                                    </div>
                                </button>

                            </div>
                        </div>
                        <Transition name="menu-list">
                            <div v-show="showMobileMenu" id="gibs-navbar-hamburger-draw" class="gibs-header-nav-mainmenu">
                                <div class="mobile-upper-hamburger">
                                    <div class="mobile-upper-hamburger-wrapper">
                                        <template v-if="computedProfileLoggedIn">
                                            <button type="button" @click="toggleProfileDropdown(false)">
                                                <div class="gibs-profile-icon">
                                                    <nuxt-icon class="text-4xl" name="gibs-profile" />
                                                </div>
                                            </button>
                                        </template>
                                        <template v-else>
                                            <button type="button" @click="toggleLoginForm">
                                                <div class="gibs-profile-icon">
                                                    <nuxt-icon class="text-4xl" name="gibs-profile" />
                                                </div>
                                            </button>
                                        </template>
                                        <NuxtLink to="/search" @click="handleRedirectToSearch">
                                            <div class="gibs-search-container" input type="text">
                                                <span class="gibs-mobile-search">Search GIBS</span>
                                                <NuxtLink class="mobile-search-icon">
                                                    <nuxt-icon name="search-icon" />
                                                </NuxtLink>
                                            </div>
                                        </NuxtLink> 
                                    </div>
                                    <Transition name="rolldown">
                                        <template v-if="showLoginForm">
                                            <div id="login-modal" class="login-modal-mobile">
                                                <div class="gibs-close-modal-btn-container">
                                                    <button class="gibs-close-modal-container" @click="toggleLoginForm">
                                                        <nuxt-icon class="login-modal-close-btn"
                                                            name="buttons/close-cross" />
                                                    </button>
                                                </div>
                                                <div v-if="!showForgotPasswordForm && !showEmailSentForm">
                                                    <div class="login-tabContent-mobile">
                                                        <GibsLoginForm :componentId="loginFormComponentId"
                                                            @successLogin="closeModalLogin" />
                                                    </div>
                                                </div>
                                                <div v-else-if="showForgotPasswordForm && !showEmailSentForm">
                                                    <div class="forgot-password">
                                                        <GibsForgotPassword :componentId="forgotPasswordFormComponentId"
                                                            @toggleResetEmailSentForm="toggleResetEmailSentForm" />
                                                    </div>
                                                </div>
                                                <div v-else-if="showEmailSentForm">
                                                    <div class="forgot-password">
                                                        <GibsPasswordResetEmail :componentId="emailSentFormComponentId"
                                                            @closeModalAndShowLogin="closeModalAndShowLogin" />
                                                    </div>
                                                </div>
                                            </div>
                                        </template>
                                    </Transition>
                                    <Transition name="rolldown">
                                        <template v-if="showProfileDropdown">
                                            <div id="gibs-profile-menu-dropdown" class="gibs-profile-menu-dropdown-mobile">
                                                <div class="gibs-close-modal-btn-container">
                                                    <button class="gibs-close-profile-menu" @click="toggleProfileDropdown(false)">
                                                        <nuxt-icon class="profile-menu-close-btn"
                                                            name="buttons/close-cross" />
                                                    </button>
                                                </div>
                                                <div class="gibs-profile-menu-container-mobile">
                                                    <ul id="gibs-profile-menu" class="gibs-profile-menu-mobile">
                                                        <li class="gibs-profile-menu-title">
                                                            <p> My GIBS Profile </p>
                                                        </li>
                                                        <li>
                                                            <div class="sub-dropdown-separator"></div>
                                                        </li>
                                                        <li class="gibs-profile-menu-item">
                                                            <GibsControlTextLink :textLinkSpec="myLearningsLink"
                                                                @click="toggleProfileAndSelect" />
                                                        </li>
                                                        <li class="gibs-profile-menu-item">
                                                            <GibsControlTextLink :textLinkSpec="profileLink"
                                                                @click="toggleProfileAndSelect" />
                                                        </li>
                                                        <li class="gibs-profile-menu-item">
                                                            <GibsControlTextLink :textLinkSpec="changePasswordLink"
                                                                @click="toggleProfileAndSelect" />
                                                        </li>
                                                        <li>
                                                            <div class="sub-dropdown-separator"></div>
                                                        </li>
                                                        <li class="gibs-profile-menu-signout">
                                                            <GibsControlTextLinkLogo :textLinkLogoSpec="signOutLink"
                                                                @click="signOutProfile" />
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </template>
                                    </Transition>
                                </div>
                                <ul id="gibs-main-menu" class="flex flex-col w-full bg-coolblack">
                                    <template v-for="mainMenuItem in mainNavigationItems">
                                        <li class="gibs-header-nav-mainmenu-item gibs-header-nav-menu-item">
                                            <NuxtLink @click="toggleMobileMenu" :to="mainMenuItem.URL"
                                                :target="mainMenuItem.Target">{{
                                                    mainMenuItem.Name }}
                                            </NuxtLink>
                                        </li>
                                    </template>
                                </ul>
                                <ul id="gibs-secondary-menu">
                                    <template v-for="item in navItems" :key="item.name">
                                        <li class="gibs-header-nav-secondarymenu-item gibs-header-nav-menu-item pt-2 pb-[5px]">
                                            <div v-if="item.items && item.items.length > 0"
                                                class="gibs-secondarymenu-item relative w-full"
                                                :id="'secondarymenu-item-' + item.name">
                                                <button @click="toggleMobileSecMenu" :id="'dropdownButton-' + item.name"
                                                    type="button" class="mobile-secondary-menu">
                                                    <span class="w-[14px]"></span>
                                                    <span>{{ item.name }}</span>
                                                    <nuxt-icon :name="(!isMobileSecondaryOpen) ? 'Dropdown' : 'DropdownUp'"
                                                        :id="'dropdownButton-' + item.name + '-icon'" />
                                                </button>
                                                <ul :id="'secondary-menu-draw-' + item.name" class="translate-y-[9px]"
                                                    :class="['dropDownItem', isMobileSecondaryOpen ? '' : 'hidden']">
                                                    <li v-for="subItem in item.items" :key="subItem.name"
                                                        class="mobile-sub-items">
                                                        <NuxtLink 
                                                            :to="subItem.url" 
                                                            :external="subItem.target === '_blank' ? true : false"
                                                            class="block py-2 pr-4 text-catalinablue rounded hover:bg-gray-100 dark:text-gray-400 dark:hover:bg-gray-700 dark:hover:text-white"
                                                            @click="clickedSubItemCloseMenu"
                                                        >
                                                            {{ subItem.name }}
                                                        </NuxtLink>
                                                    </li>
                                                </ul>

                                            </div>
                                            <NuxtLink v-else 
                                                :to="item.url" 
                                                :external="item.target === '_blank' ? true : false" 
                                                @click="toggleMobileMenu"
                                                class="mobile-secondary-menu mobile-initial-items"
                                            >
                                                {{ item.name }}
                                            </NuxtLink>
                                        </li>
                                    </template>
                                </ul>
                                <div class="gibs-menu-course-finder-container">
                                    <GibsControlCourseFinderButton :courseFinderButtonSpec="courseFinderSpec" @click="toggleMobileMenu()" />
                                </div>
                            </div>
                        </Transition>
                    </div>
                </nav>
            </template>
        </header>

        <main id="gibs-main-page" class="gibs-main min-h-[60vh] relative">
            <div v-if="loginRedirectCode.length > 0" class="gibs-social-login-wrapper">
                <div class="gibs-social-login-containers">
                    <div id="gibs-social-login-spinner" class="gibs-social-login-spinner">
                        <span class="loader"></span>
                    </div>
                </div>
            </div>
         
                <GibsBreadcrumb :fullPath="route.path" />
         
                <slot></slot>
            
            <template v-if="loadingState">
                <div class="gibs-page-app">
                    <div class="gibs-content-body">
                        <div class="gibs-page-layout">
                            <div class="h-112.5">
                                <div class="gibs-page-loader">
                                    <h2 class="w-fit h-fit">We are processing your request, please be patient...</h2> 
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </template> 
        </main>

        <footer id="gibs-footer" class="gibs-footer-container">
            <lazy-component>
                <ClientOnly>
                    <GibsPageFooter :layoutView="layoutView" @toggleModal="toggleModal" />
                </ClientOnly>
                <template #placeholder>
                    <span id="FooterLoading">Loading...</span>
                </template>
            </lazy-component>
        </footer>

        <client-only>
            <GibsControlModalComponent :isModalOpen="isModalOpen" :modalId="'gibs-newsletter-modal'"
                @closeModal="closeModal">
                <template v-slot:modal-content>
                    <GibsSubscribeNewsletterForm :profileLoggedIn="computedProfileLoggedIn" @closeModal="closeModal" />
                </template>
            </GibsControlModalComponent>

            <GibsControlModalComponent :isModalOpen="isVideoModalOpen" :modalId="'gibs-video-modal'"
                @closeModal="closeVideoModal">
                <template v-slot:modal-content>
                    <div class="gibs-video-modal-container">
                        <template v-if="videoType === 'youtube'">
                            <YouTube :key="renderKey" :src="videoUrl" ref="youtube" :vars="{ autoplay: 0, controls: 1 }" />
                        </template>
                        <template v-else-if="videoType === 'vimeo'">
                            <vue-vimeo-player :key="renderKey" :video-id="videoUrl" />
                        </template>
                    </div>
                </template>
            </GibsControlModalComponent>

            <GibsControlModalComponent :isModalOpen="isAddFormModalOpen" :modalId="'gibs-add-form-modal'"
                @closeModal="closeAddFormModal">
                <template v-slot:modal-content>
                    <!-- <GibsControlStepperSelectedContentInputs :appendId="'-modal'" /> -->
                    <GibsFormsContentBaseModal 
                        :appendId="'-modal'"
                        :Title="SectionDescription.Qualification" >
                    </GibsFormsContentBaseModal>
                </template>
            </GibsControlModalComponent>

            <GibsControlModalComponent :isModalOpen="isShareFormModalOpen" :modalId="'gibs-share-programme-modal'"
                @closeModal="closeShareFormModal" @toggleShareFormModal="toggleShareFormModal">
                <template v-slot:modal-content>
                    <GibsShareProgrammeForm @closeModal="closeShareFormModal" idPrefix="modal"/>
                </template>
            </GibsControlModalComponent>

            <GibsControlModalComponent :isModalOpen="isApplicationClosedModalOpen" :modalId="'gibs-application-closed-modal'"
                @closeModal="closeApplicationClosedModal" @toggleApplicationClosedModal="toggleApplicationClosedModal">
                <template v-slot:modal-content>
                    <GibsApplicationClosedForm @closeModal="closeApplicationClosedModal" :loggedinName="computedProfileName" :isLoggedIn="computedProfileLoggedIn" :loggedinSurname="computedProfileSurname"/>
                </template>
            </GibsControlModalComponent>
            
            <GibsControlModalComponent :isModalOpen="isAbortEnrolmentModalOpen" :modalId="'gibs-abort-enrolment-modal'"
                @closeModal="closeAbortEnrolmentModal" @toggleAbortEnrolmentModal="toggleAbortEnrolmentModal">
                <template v-slot:modal-content>
                    <GibsControlConfirmationPopup @closeModal="closeAbortEnrolmentModal"/>
                </template>
            </GibsControlModalComponent>

            <GibsControlModalComponent :isModalOpen="isOnlinePaymentModalOpen" :modalId="'gibs-form-online-payment-modal'"
                @closeModal="closeOnlinePaymentModal" @toggleOnlinePaymentModal="toggleOnlinePaymentModal">
                <template v-slot:modal-content>
                    <GibsControlPaymentConfirmationPopup @closeModal="closeOnlinePaymentModal" 
                    buttonText="Confirm payment" confirmationText="An invoice number will be generated and you will be redirected to Paygate. Are you sure you want to continue?"/>
                </template>
            </GibsControlModalComponent>
        </client-only>
    </div>
</template>

<script lang="ts" setup>
import { z } from 'zod';
import LazyComponent from 'v-lazy-component';
import YouTube from 'vue3-youtube';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import { useNotificationStore } from '~/stores/notification';
import { useProgShareStore } from '~/stores/programme-share';
import { useAbortEnrolmentStore } from '~/stores/abort-enrolment';
import GibsControlLoading from '~/components/gibs/control/loading.vue';
const loadingRef = ref<typeof GibsControlLoading>();
import { useFormOnlinePaymentStore } from '~/stores/online-payment';
import { SessionKeys } from '~/utils/enums';
import { useActiveMenuStateStore } from '~/stores/activeMenuState';
import { persistSitemapData } from '~/utils/storage';

const router = useRouter();
const route = useRoute();
const setMenuItemManually = ref<boolean>(false);
const routeHistoryStore = useRouteHistoryStore();
const scrollPosition = ref(0);
const currentUriFullPath = ref(route.fullPath);
let loginRedirectCode = ref('');
let postLoginRedirectUrl = ref('');

const siteMapStore = useSiteMapStore();
await siteMapStore.fetchSiteMap();
const currentSiteMapPath = siteMapStore.getSiteMap;

const userSession = useCookie<{ sessionId: string }>('userSession');

const activeMenuState = useActiveMenuStateStore();

onMounted(async () => {

    persistSitemapData(siteMapStore.getSiteMapData);

    const fullPageEl = document.querySelector("#gibs-full-page");
    const bodyEl = document.querySelector("body");

    postLoginRedirectUrl.value = (localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/');
    const urlParams = new URLSearchParams(window.location.search);

    loginRedirectCode.value = urlParams.get('access_token') || urlParams.get('code') || urlParams.get('local') || '';
    // TODO : 20240311 - Check social login should have some checks as this causes errors on the loading timeout
    checkSocialLoginRedirectParams();
    
    store = useNotificationStore();
    fullPageEl?.classList.remove("invisible");

    setMenuAsActive();

    /**
     * Watches for changes in the socialLoginRedirectCode value and updates the body's overflow style accordingly.
     * If the socialLoginRedirectCode value is not empty, sets the body's overflow style to 'hidden'.
     * Otherwise, removes the overflow style from the body.
     */
    watchEffect(() => {
        if (loginRedirectCode.value.length > 0) {
            // Remember the current scroll position
            scrollPosition.value = window.scrollY;
            // scroll to top
            window.requestAnimationFrame(() => {
            window.scrollTo(0, 0);
            });
        } else {
            
            // scroll to previous position
            window.requestAnimationFrame(() => {
                window.scrollTo(0, scrollPosition.value);
            });

            if (loadingRef.value != null || loadingRef.value != undefined){
                loadingRef.value.hideSpinner();
            }

        }
    });

    // Check if the sessionId exists, if not, create a new one
    watchEffect(() => {
        // TODO : When logging out, destroy sessionId and all cookies
        if (!userSession.value?.sessionId || userSession.value?.sessionId === Guid.Empty.toString()) {
            userSession.value = { sessionId: newGuid() };
            refreshCookie('userSession');
        }
    });
});

watch(() => route.fullPath, async (newPath: String) => {
    resetMenuItemActive();
    setMenuAsActive();
    routeHistoryStore.addRoute(newPath);

    // TODO: Review this with @Neil, think my code is flaky...
    // if we are navigating to the same page as the postLoginRedirectUrl, remove the postLoginRedirectUrl from localStorage and we are not on the home page
    if (newPath === postLoginRedirectUrl.value && newPath !== '/' && newPath !== '') {
        localStorage.removeItem(SessionKeys.PostLoginRedirectUrl);
    }
    
    sessionRefreshed.value++;
    if ((localStorage.getItem(SessionKeys.PostLoginRedirectUrl)) !== null) {
        // Reset the scroll position if we will redirect to a new page
        scrollPosition.value = 0;
    }
    currentUriFullPath.value = newPath;
    const urlParams = new URLSearchParams(window.location.search);
    loginRedirectCode.value = urlParams.get('access_token') || urlParams.get('code') || urlParams.get('local') || '';
    checkSocialLoginRedirectParams();
    if (computedProfileLoggedIn.value && currentUriFullPath.value.toLocaleLowerCase().includes('/account/')) {
        try {
            postLoginRedirectUrl.value = (localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/');
            console.log(`Redirecting to: ${ensureRelativeURL(postLoginRedirectUrl.value)}`);
            await router.push(ensureRelativeURL(postLoginRedirectUrl.value))
                .catch(error => {
                    console.error('Error during route navigation:', error, 'postLoginRedirectUrl.value:', ensureRelativeURL(postLoginRedirectUrl.value));
                });
            //localStorage.removeItem('postLoginRedirectUrl');
        } catch (error) {
            console.error(`Error during redirection: ${error}`);
        }
    }

    // removing script components from info central
    const element1 = document.getElementById("lcs_slide_out-15270");
    const element2 = document.getElementById("s-la-widget-activator-14403");

    if (element1 && route.fullPath != "/info-central") {
        element1.remove();
    }

    if (element2 && route.fullPath != "/info-central") {
        element2.remove();
    }
});

const selectedMenuItemIndex = ref<number>(-1);

function resetMenuItemActive() {
    if (activeMenuState.getIsMenuItemManuallySet) {
        const menuItemElementList = document.querySelectorAll(".gibs-header-nav-menu-item");
            if (menuItemElementList.length > 0 && menuItemElementList[selectedMenuItemIndex.value] && menuItemElementList[selectedMenuItemIndex.value].firstChild) {
                menuItemElementList[selectedMenuItemIndex.value].firstChild.classList.remove("router-link-active");
                menuItemElementList[selectedMenuItemIndex.value].firstChild.classList.remove("router-link-exact-active");
            }
        activeMenuState.setIsMenuItemManuallySet(false);
    }
}

function setMenuAsActive() {
    const selectedMenuItemElement = document.querySelectorAll(".gibs-header-nav-menu-item .router-link-active");
    let menuItemElementList = document.querySelectorAll(".gibs-header-nav-menu-item");
    if (selectedMenuItemElement && selectedMenuItemElement.length === 0) {
        if (currentSiteMapPath) {
            const urlSplit = route.path.split("/");
            const urlName = urlSplit[urlSplit.length - 1].toLowerCase().replace("/","").replaceAll("-", " ");
            const levelOnePath = currentSiteMapPath.getUrlBreadcrumb(route.path).toLowerCase() != "" ? currentSiteMapPath.getUrlBreadcrumb(route.path).toLowerCase() : urlName;
            // console.log(levelOnePath)
            if (levelOnePath != "") {
                menuItemElementList.forEach((element, index) => {
                    // check if there's a match
                    if (element.firstChild && element.firstChild.text && (element.firstChild.text.toLowerCase().replace("|","").toString() == levelOnePath || levelOnePath.includes(element.firstChild.text.toLowerCase()))) {
                        element.firstChild.classList.add("router-link-active");
                        element.firstChild.classList.add("router-link-exact-active");
                        setMenuItemManually.value = true;
                        activeMenuState.setIsMenuItemManuallySet(true);
                        selectedMenuItemIndex.value = index;
                    }
                });
            }
        }
    }
}

async function checkSocialLoginRedirectParams() {
    // Google login redirect
    if (currentUriFullPath.value !== '' &&
        currentUriFullPath.value !== null &&
        currentUriFullPath.value !== undefined &&
        currentUriFullPath.value.includes('access_token')) {
        try {
            loadingRef.value.LoadData(CheckGoogleInLogin);
        } catch (error) {
            console.error('GoogleInLogin Error:', error);
        } finally {
            if (loadingRef.value != null || loadingRef.value != undefined){
                loadingRef.value.hideSpinner();
            }
        }
    }

    // LinkedIn login redirect
    if (currentUriFullPath.value !== '' &&
        currentUriFullPath.value !== null &&
        currentUriFullPath.value !== undefined &&
        currentUriFullPath.value.includes('code')) {
        try {
            loadingRef.value.LoadData(CheckLinkedInLogin);
        } catch (error) {
            console.error('LinkedInLogin Error:', error);
        } finally {
            if (loadingRef.value != null || loadingRef.value != undefined){
                loadingRef.value.hideSpinner();
            }
        }
    }

    async function CheckGoogleInLogin() {
        // Prepare the request body
        const currentHomePage = window.location.origin;
        const body = {
            provider: 'google',
            googleLoginAccessToken: loginRedirectCode.value
        };

        // Make a POST request to the login endpoint
        const loginResponse: (z.infer<typeof FetchLoginResponse>) = await $fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        // Check if the request was successful
        if (loginResponse && loginResponse.gibsSocialLoginResponse && loginResponse.gibsSocialLoginResponse.success) {
            // Fetch the user profile from the session
            const { data: profile, error, status } = await useLoaderFetch('/api/auth/user', {
                method: 'GET'
            });
            const parsedData = GibsUserSession.safeParse(profile.value);
            
            if (parsedData.success) {

                notification = {
                    type: "success",
                    title: "Google log in.",
                    body: "You have successfully signed into your profile",
                    date: new Date(),
                    timeout: true,
                    timer: 5000
                };

                // Emit the successLogin event
                emit('successLogin', true);
                computedProfileLoggedInString.value = 'true';
                computedProfileLoggedIn.value = true;
                localStorage.setItem('Is_Logged_In', 'true');
                localStorage.setItem('Logged_In_Name', parsedData.data.userName);
                localStorage.setItem('Logged_In_Surname', parsedData.data.lastName);
                //aamz
            } else {
                // An error occurred while parsing the user session data
                console.error('Google loginResponse failed, user session not authenticated after login (Error)', JSON.stringify(parsedData, null, 2));
                throw new Error('Google Login failed, user session not authenticated after login');
            }
            
            emit('successLogin', true);
            sessionRefreshed.value++;
            loginRedirectCode.value = '';
            let redirectUrl = (localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/');
            if (redirectUrl !== '/') {
                //localStorage.removeItem('postLoginRedirectUrl');
                await router.push(ensureRelativeURL(redirectUrl))
                    .catch(error => {
                        console.error('Error during route navigation:', error, 'redirectUrl:', ensureRelativeURL(redirectUrl));
                    });
            } else {
                await router.push("/")
                    .catch(error => {
                        console.error('Error during route navigation:', error, 'postLoginRedirectUrl.value:', '/');
                    });
            }
        } else {
            console.error('Google loginResponse failed (Error)', JSON.stringify(loginResponse, null, 2));
            const notification: INotification = {
                type: "error",
                title: "Google log in failed.",
                body: loginResponse.error.toString(),
                date: new Date(),
                timeout: true,
                timer: 10000
            };
            store.addNotification(notification);
            throw new Error('Google Login failed');
        }

        store.addNotification(notification);
    }

    async function CheckLinkedInLogin() {
        // Prepare the request body
        const currentHomePage = window.location.origin;
        const accessTokenUrlParams = {
            'grant_type': 'authorization_code',
            'code': loginRedirectCode.value,
            'redirect_uri': currentHomePage,
            'client_id': "",
            'client_secret': "" // Set server-side
        };
        const body = {
            provider: 'linkedIn',
            params: accessTokenUrlParams
        };

        // Make a POST request to the login endpoint
        const loginResponse: (z.infer<typeof FetchLoginResponse>) = await $fetch('/api/auth/login', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(body)
        });

        // Check if the request was successful
        if (loginResponse && loginResponse.gibsSocialLoginResponse && loginResponse.gibsSocialLoginResponse.success) {
            // Fetch the user profile from the session
            const { data: profile, error, status } = await useLoaderFetch('/api/auth/user', {
                method: 'GET'
            });
            const parsedData = GibsUserSession.safeParse(profile.value);
            
            if (parsedData.success) {

                notification = {
                    type: "success",
                    title: "LinkedIn log in.",
                    body: "You have successfully signed into your profile",
                    date: new Date(),
                    timeout: true,
                    timer: 5000
                };

                // Emit the successLogin event
                emit('successLogin', true);
                computedProfileLoggedInString.value = 'true';
                computedProfileLoggedIn.value = true;
                localStorage.setItem('Is_Logged_In', 'true');
                localStorage.setItem('Logged_In_Name', parsedData.data.userName);
                localStorage.setItem('Logged_In_Surname', parsedData.data.lastName);
            } else {
                // An error occurred while parsing the user session data
                console.error('LinkedIn loginResponse failed, user session not authenticated after login (Error)', JSON.stringify(parsedData, null, 2));
                throw new Error('LinkedIn Login failed, user session not authenticated after login');
            }
            
            emit('successLogin', true);
            sessionRefreshed.value++;
            loginRedirectCode.value = '';
            let redirectUrl = (localStorage.getItem(SessionKeys.PostLoginRedirectUrl) || '/');
            if (redirectUrl !== '/') {
                //localStorage.removeItem('postLoginRedirectUrl');
                await router.push(ensureRelativeURL(redirectUrl))
                    .catch(error => {
                        console.error('Error during route navigation:', error, 'redirectUrl:', ensureRelativeURL(redirectUrl));
                    });
            } else {
                await router.push("/")
                    .catch(error => {
                        console.error('Error during route navigation:', error, 'postLoginRedirectUrl.value:', '/');
                    });
            }
        } else {
            console.error('LinkedIn loginResponse failed (Error)', JSON.stringify(loginResponse, null, 2));
            const notification: INotification = {
                type: "error",
                title: "LinkedIn log in failed.",
                body: loginResponse.error.toString(),
                date: new Date(),
                timeout: true,
                timer: 10000
            };
            store.addNotification(notification);
            throw new Error('LinkedIn Login failed');
        }

        store.addNotification(notification);
    }
}
    
    
const config = useRuntimeConfig();
const fpStore = useForgotPasswordStore();
const emit = defineEmits(['successLogin']);

const shareProgStore = useProgShareStore();
const applicationClosedStore = useProgApplicationClosedStore();
const abortEnrolmentStore = useAbortEnrolmentStore();
const formOnlinePaymentStore = useFormOnlinePaymentStore();

// Ensure correct preload and prefetch headers are present
await populateHead();

const props = defineProps({
    layoutView: { type: Object, default: { view: "", windowWidth: 0, refresh: 0 } },
    mobileSubmenuOpenProp: { type: Object, default: null },
    showTestNavProp: { type: Boolean, default: false },
    secondaryNavigationProp: { type: Array, default: [] },
    mobileMenuOpenProp: { type: Boolean, default: false },
    dropdownOpenProp: { type: Object, default: null },
    expandedProp: { type: Boolean, default: false },
    mainNavigationItemsProp: { type: Array<IMainMenuItem>, defaut: new Array<IMainMenuItem>() },
    isHamburgerMenuOpenProp: { type: Boolean, default: false },
    isMobileSecondaryMenuOpenProp: { type: Boolean, default: false },
    isDesktopSecondaryMenuOpenProp: { type: Boolean, default: false },
});

const courseFinderSpec: IControlButton = {
    buttonText: "Course Finder",
    class: "",
    enableUrl: true,
    externalSource: false,
    sourceUrl: "/course-finder",
};

// Profile menu items
const myLearningsLink: IControlButton = {
    buttonText: "My learning journey",
    class: "text-lg",
    enableUrl: true,
    externalSource: false,
    sourceUrl: "/profile/my-learning-journey",
};
const profileLink: IControlButton = {
    buttonText: "Personal details",
    class: "text-lg",
    enableUrl: true,
    externalSource: false,
    sourceUrl: "/profile/personal-details",
};
const changePasswordLink: IControlButton = {
    buttonText: "Change my password",
    class: "text-lg",
    enableUrl: true,
    externalSource: false,
    sourceUrl: "/profile/change-my-password",
};
const signOutLink: IControlButton = {
    buttonElName: "gibs-profile-signout",
    buttonText: "SIGN OUT",
    class: "text-xl font-semibold",
    enableUrl: true,
    externalSource: false,
    sourceUrl: "/",
    type: "signout"
};


const showForgotPasswordForm = ref(false);
const showEmailSentForm = ref(false);
const showLoginForm = ref(false);
const showProfileDropdown = ref(false);
const isHamburgerMenuOpen = ref(props.isHamburgerMenuOpenProp);
const isDesktopSecondaryMenuOpen = ref(props.isMobileSecondaryMenuOpenProp);
const isMobileSecondaryMenuOpen = ref(props.isMobileSecondaryMenuOpenProp);
const loginFormComponentId = ref<string>("gibs-account-login-modal");
const forgotPasswordFormComponentId = ref<string>("gibs-forgot-password-modal");
const emailSentFormComponentId = ref<string>("gibs-email-sent-modal");
const isModalOpen = ref<boolean>(false);
const isShareFormModalOpen = ref<boolean>(false);
const isApplicationClosedModalOpen = ref<boolean>(false);
const isAbortEnrolmentModalOpen = ref<boolean>(false);
const isOnlinePaymentModalOpen = ref<boolean>(false);

const renderKey = ref(0);

let dropdownOpen = props.dropdownOpenProp as ISecondaryNavigation | null;
const finalSocialProfile = ref();
let notification: INotification;
let store = useNotificationStore();
let formStore = useFormsStore();
let videoStore = useVideoStore();
const videoModalObj = ref(videoStore.getVideoModal);
const isVideoModalOpen = ref<boolean>(false);
const videoUrl = ref<string>(videoStore.getVideoModal.src);
const videoType = ref<string>(videoStore.getVideoModal.type);

let addFormStore = useAddFormStore();
const addFormModalObj = ref(addFormStore.getAddFormModal);
const isAddFormModalOpen = ref<boolean>(false);

let navItems: ISecondaryNavigation[] = new Array<ISecondaryNavigation>();
let secNavItemsResp: ISecondaryNavigationQuery[] = new Array<ISecondaryNavigationQuery>();
let mainNavigationItems: IMainMenuItem[] = props.mainNavigationItemsProp as IMainMenuItem[];

const showMobileMenu = ref(false);

let sessionRefreshed = ref(0);
let computedProfileLoggedIn = ref(false); // default value
let computedProfileLoggedInString = ref('false'); // default value
let computedProfileName = ref('Unauthenticated'); // default value
let computedProfileSurname = ref<string|null|undefined>('Unauthenticated'); // default value
const refresh = ref<number>(0);


const CheckEnrolMessage = async()=>{

    const isSuccess: string = 'success';
    const isError: string = 'error';

    var check = localStorage.getItem('DoEnrolmentMessage');
    if('true' == check) {
        localStorage.setItem('DoEnrolmentMessage', 'false');
        
        //Get login users enrolments
        // DO NOT useLoaderFetch
        const { data: enrolmentsResponse, error, status } = await useFetch('/api/profile/enrolments', {
            method: 'POST',
            body: ''
        });

        if (status.value === isSuccess) {
            const  myLearningsList = enrolmentsResponse.value as string;
            let parsedLearningList: z.infer<typeof MyLearningsQuery> | null;
            try {
                const parseResult = MyLearningsQuery.safeParse(JSON.parse(myLearningsList));
                if (parseResult.success) {
                    parsedLearningList = parseResult.data;
                } 
                else {
                    parsedLearningList = null;
                    console.log('Error parsing JSON');
                }

            }
            catch (err) {
                parsedLearningList = null;
                console.log('Error parsing JSON');
            }
            
            if (parsedLearningList != null && parsedLearningList != undefined){
            
                if(parsedLearningList.inProgressEnrolments.length >0) {
                    // Check the inprogress enrolments that still need user actions
                    const actionableEnrolment = parsedLearningList.inProgressEnrolments.find((enrolment: z.infer<typeof EnrolmentQuery>) => {
                        return (enrolment.enrolmentStatusLevel == EnrollmentLevels.Start || enrolment.enrolmentStatusLevel == EnrollmentLevels.Outstandinginformation);
                    });

                    // Dont show notificaton if busy on the apply and profile my-learning-journey page
                    const url = useRequestURL();
                    if (typeof(actionableEnrolment) !== 'undefined' && (!url.pathname.includes('/apply') && !url.pathname.includes('/profile/my-learning-journey'))) {
                        notification = {
                            type: "note",
                            title: "Saved enrolments.",
                            body: "You have saved enrolments, click the button to manage your enrolments.",
                            date: new Date(),
                            buttonText: "Manage enrolments",
                            LinkUrl: "/profile/my-learning-journey",
                            timeout: false
                        };
                        store.addNotification(notification);
                    }
                }
            }
        }
        else if(status.value === isError) {
            notification = {
                type: "error",
                title: "Enrolments.",
                body: "Failed to retrieve enrolments for user my learnings.", // do not send this error >> error.value!.toString(),
                date: new Date(),
                timeout: false
            };
            // ToDo: Return this line once call to '/api/profile/enrolments' is
            //       sub 15 seconds
            //store.addNotification(notification);
        }
    }
}

watchEffect(async () => {
    sessionRefreshed.value;

    const userSessionData = await $fetch('/api/auth/user');
    const parsedData = GibsUserSession.safeParse(userSessionData);

    if (parsedData.success) {
        applicationClosedStore.setIsLoggedIn(parsedData.data.isLoggedIn);
    } else {
        applicationClosedStore.setIsLoggedIn(false);
    }
    computedProfileLoggedIn.value = (parsedData.success ? parsedData.data.isLoggedIn : false);
    computedProfileName.value = parsedData.success ? parsedData.data.userName : 'Unauthenticated'; 
    computedProfileSurname.value = parsedData.success ? parsedData.data.lastName : 'Unauthenticated'; 
    
    if(computedProfileName.value != 'Unauthenticated'){
        CheckEnrolMessage();
        computedProfileLoggedInString.value = 'true';
        localStorage.setItem('Is_Logged_In', computedProfileLoggedInString.value);
        localStorage.setItem('Logged_In_Name', computedProfileName.value);
        localStorage.setItem('Logged_In_Surname', computedProfileSurname.value);
    }

    // Add this check before using localStorage
    if (process.client) {
        if (!computedProfileLoggedIn.value) { 
            computedProfileLoggedInString.value = 'false';
            localStorage.removeItem('Is_Logged_In');
            localStorage.removeItem('Logged_In_Name');
            localStorage.removeItem('Logged_In_Surname');
        }
        else {
            console.log('uri', currentUriFullPath.value); 
            if (currentUriFullPath.value.indexOf('/user-verification') > -1) {
                if (process.client) {
                    // User is logged in, probably not the one being verified
                    // Check if user is authenticated
                    let request = {request: '/api/auth/user', date: new Date(), status: 'Busy'};
                    if (loadingRef.value != undefined || loadingRef.value != null)
                    {
                        loadingRef.value.setFetchData(request);
                    }
                    const userSessionData = await $fetch('/api/auth/user');
                    if (loadingRef.value != undefined || loadingRef.value != null)
                    {
                        loadingRef.value.removeFetchData(request);
                    }

                    const gibsUserSession = GibsUserSession.safeParse(userSessionData);
                    if (gibsUserSession.success && gibsUserSession.data.isLoggedIn == true) {
                        // Ensure no user is logged out when loading the verification page
                        const response = await fetch('/api/auth/logout', { method: 'POST' });
                        if (response.ok) {
                            // Refresh the sessionId when logging out
                            if (!userSession.value?.sessionId || userSession.value?.sessionId !== Guid.Empty.toString()) {
                                userSession.value = { sessionId: newGuid().toString() };
                                localStorage.setItem('Is_Logged_In', 'false');
                                refreshCookie('userSession');
                            } 
                            sessionRefreshed.value++;
                        }
                    }
                }
            }
        }
    }
    refresh.value ++;
});

const show = ref<boolean>(false);
watch(()=> computedProfileLoggedIn.value, (newValue) => {
    show.value = newValue;
})

let isMobileSecondaryOpen = computed({
    get() {
        return isMobileSecondaryMenuOpen.value;
    },
    set(isOpen) {
        isMobileSecondaryOpen.value = isOpen;
    }
});

let isDesktopSecondaryOpen = computed({
    get() {
        return isDesktopSecondaryMenuOpen.value;
    },
    set(isOpen) {
        isDesktopSecondaryOpen.value = isOpen;
    }
});

function toggleModal() {
    isModalOpen.value = true;
}

function closeModal(emit: boolean) {
    isModalOpen.value = emit;
}

function closeAddFormModal(emit: boolean) {
    isAddFormModalOpen.value = emit;
    addFormStore.closeAddFormModal();
}

function closeVideoModal(emit: boolean) {
    isVideoModalOpen.value = emit;
    videoStore.isOpenVideoModal(emit);
    // re-renders the video modal component so video stops playing when closed
    renderKey.value = renderKey.value + 1;
}

function setupsrcUrl() {
    renderKey.value = renderKey.value + 1;  // resets the modal to prevent autoplay
    videoUrl.value = videoStore.getVideoModal.src;
}

function toggleMobileMenu() {
    showMobileMenu.value = !showMobileMenu.value;
    toggleMobileMenuStyling(showMobileMenu.value);
    fpStore.isClosedForgotPassword();
}

function closeMobileMenu() {
    showMobileMenu.value = false;
    toggleMobileMenuStyling(false);
}

function handleRedirectToSearch() {
    showMobileMenu.value = !showMobileMenu.value;
    toggleMobileMenuStyling(showMobileMenu.value);
    fpStore.isClosedForgotPassword();
}

function toggleMobileMenuStyling(showMenu: boolean) {
    const topHeaderId = 'gibs-header';
    const bodyTag = 'body';
    const topHeaderElement = document.getElementById(topHeaderId) as HTMLElement;
    const bodyElement = document.querySelector(bodyTag) as HTMLElement;

    if (showMenu) {
        topHeaderElement.classList.add('expand-full-height');
        bodyElement.classList.add('overflow-hidden');
        isHamburgerMenuOpen.value = true;
    } else {
        topHeaderElement.classList.remove('expand-full-height');
        bodyElement.classList.remove('overflow-hidden');
        toggleMobileSecMenuStyling(false);
        isHamburgerMenuOpen.value = false;
    }
}

function toggleDesktopSecMenu() {
    isDesktopSecondaryMenuOpen.value = !isDesktopSecondaryMenuOpen.value;
    toggleDesktopSecMenuStyling(isDesktopSecondaryMenuOpen.value);
}

function toggleDesktopSecMenuStyling(showMenu: boolean) {
    navItems.forEach((item: ISecondaryNavigation) => {
        const menuId = 'desktop-dropdownButton-' + item.name;
        const drawId = 'desktop-secondary-menu-draw-' + item.name;
        const buttonEl = document.getElementById(menuId);
        const dropdownElement = document.getElementById(drawId);
        if (showMenu) {
            isDesktopSecondaryMenuOpen.value = true;
            dropdownElement?.classList.remove('hidden');
            buttonEl?.classList.add('list-open');
            if (dropdownOpen === item) {
                dropdownOpen = null;
            } else {
                dropdownOpen = item;
            }
        } else {
            isDesktopSecondaryMenuOpen.value = false;
            dropdownElement?.classList.add('hidden');
            buttonEl?.classList.remove('list-open');
        }
    });
}

function clickedSubItemCloseMenu() {
    toggleMobileSecMenu();
    toggleMobileMenu();
}

function toggleMobileSecMenu() {
    isMobileSecondaryMenuOpen.value = !isMobileSecondaryMenuOpen.value;
    toggleMobileSecMenuStyling(isMobileSecondaryMenuOpen.value);
}

function toggleMobileSecMenuStyling(showMenu: boolean) {
    navItems.forEach((item: ISecondaryNavigation) => {
        const menuId = 'dropdownButton-' + item.name;
        const drawId = 'secondary-menu-draw-' + item.name;
        const buttonId = 'dropdownButton-' + item.name;
        const arrowIcon = document.getElementById('dropdownButton-' + item.name + '-icon');
        const secondaryMenuItem = document.getElementById('secondarymenu-item-' + item.name);
        const dropdownElement = document.getElementById(drawId);
        const buttonElement = document.getElementById(buttonId);
        if (showMenu) {
            isMobileSecondaryMenuOpen.value = true;
            dropdownElement?.classList.remove('hidden');
            buttonElement?.classList.add('active-secondary-dropdown-heading');
            arrowIcon?.classList.add('active-dropdown-secondary-arrow');

            if (secondaryMenuItem) {
                let height = (item.items!.length * 40) + 12;
                secondaryMenuItem!.style.height = height + "px";
            }

            if (dropdownOpen === item) {
                dropdownOpen = null;
            } else {
                dropdownOpen = item;
            }
        } else {
            isMobileSecondaryMenuOpen.value = false;
            if (secondaryMenuItem) {
                secondaryMenuItem!.style.height = 15 + "px";
            }
            buttonElement?.classList.remove('active-secondary-dropdown-heading');
            arrowIcon?.classList.remove('active-dropdown-secondary-arrow');
        }
    });
}

function toggleResetEmailSentForm() {
    showEmailSentForm.value = !showEmailSentForm.value;
}

function closeModalAndShowLogin(emit: boolean) {
    showEmailSentForm.value = emit;
    showForgotPasswordForm.value = emit;
    showLoginForm.value = (computedProfileLoggedIn.value)? false : true;
}

async function getMainMenuNavigationItems() {
    //const fetchNavItems = await $fetch("/api/menu/MainMenuItems");
    //mainNavigationItems = fetchNavItems.body as IMainMenuItem[];
    const fetchNavItems = await fetchJsonData('/Menu/MainMenuItems');
    mainNavigationItems = MainMenuItems.parse(fetchNavItems) as IMainMenuItem[];

    return Promise.resolve();
}

async function getSecondaryNavigationItems() {
    // const fetchNavItems = await $fetch("/api/menu/SecondaryMenuItems");
    // secNavItemsResp = fetchNavItems.body as ISecondaryNavigationQuery[];
    const fetchNavItems = await fetchJsonData('Menu/SecondaryMenuItems');
    secNavItemsResp = SecondaryNavigationQuery.parse(fetchNavItems) as ISecondaryNavigationQuery[];

    return Promise.resolve();
}

function transformItems(items: ISecondaryNavigationQuery[]): ISecondaryNavigation[] {
    return items.map((item: ISecondaryNavigationQuery) => {
        const transformedItem: ISecondaryNavigation = {
            name: item.Name,
            url: item.URL,
            target: item.Target,
            items: item.Items ? transformItems(item.Items) : null,
            expanded: item.expanded
        };
        return transformedItem;
    });
};

function toggleLoginForm() {
    showLoginForm.value = !showLoginForm.value;
    showForgotPasswordForm.value = false;
    showEmailSentForm.value = false;
    fpStore.isClosedForgotPassword();
}

function toggleProfileDropdown(hasSourceLink: boolean = false) {
    if (hasSourceLink){
        loadingRef.value = formStore.getLoader().value;
        loadingRef.value.showSpinnerFor();
    }
    showProfileDropdown.value = !showProfileDropdown.value;
}

function toggleProfileAndSelect() {
    showProfileDropdown.value = false;
    showMobileMenu.value = false;
    toggleMobileMenuStyling(showMobileMenu.value);
}

function toggleShareFormModal() {
    isShareFormModalOpen.value = !isShareFormModalOpen.value; 
    shareProgStore.isClosedProgShareModal();
}

function closeShareFormModal(emit: boolean) {
    isShareFormModalOpen.value = emit;
 
    if (!emit) {
        shareProgStore.isClosedProgShareModal();
    }
}

function toggleApplicationClosedModal() {
    isApplicationClosedModalOpen.value = !isApplicationClosedModalOpen.value; 
    applicationClosedStore.isClosedApplicationClosedModal();
}

function closeApplicationClosedModal(emit: boolean) {
    isApplicationClosedModalOpen.value = emit;
 
    if (!emit) {
        applicationClosedStore.isClosedApplicationClosedModal();
    }
}

function toggleAbortEnrolmentModal() {
    isAbortEnrolmentModalOpen.value = !isAbortEnrolmentModalOpen.value; 
    abortEnrolmentStore.isClosedAbortEnrolmentModal();
}

function closeAbortEnrolmentModal(emit: boolean) {
    isAbortEnrolmentModalOpen.value = emit;
 
    if (!emit) {
        abortEnrolmentStore.isClosedAbortEnrolmentModal();
    }
}

function toggleOnlinePaymentModal() {
    isOnlinePaymentModalOpen.value = !isOnlinePaymentModalOpen.value; 
    formOnlinePaymentStore.isClosedFormOnlinePaymentModal();
}

function closeOnlinePaymentModal(emit: boolean) {
    isOnlinePaymentModalOpen.value = emit;
 
    if (!emit) {
        formOnlinePaymentStore.isClosedFormOnlinePaymentModal();
    }
}

function closeModalLogin(emit: boolean) {
    // if login success, close the login modal
    if (emit === true) {
        showLoginForm.value = false;
        sessionRefreshed.value++;
    }
    if (props.layoutView.view === "mobile") {
        showMobileMenu.value = false;
        toggleMobileMenuStyling(showMobileMenu.value);
    }
}

useHead({
  meta: [
    {
      name: 'google-site-verification',
      content: 'gQQeVgxr25b8fws40MmacpEtR9nqj05cVIg0H0APnYE'
    }
  ]
});

async function populateHead() {
    useHead(() => {
        const imgUri = config.public.imgUri;
        const preconnectLink = {
            rel: 'preconnect',
            href: imgUri,
        };

        const fontLinks = [
            {
                rel: 'preload',
                href: '/fonts/JubilatBold-Roman.otf',
                crossorigin: '',
                as: 'font',
                type: 'font/otf',
            },
            {
                rel: 'preload',
                href: '/fonts/JubilatSemibold-Roman.otf',
                crossorigin: '',
                as: 'font',
                type: 'font/otf',
            },
            {
                rel: 'preload',
                href: '/fonts/WOFF2-metric-bold.woff2',
                crossorigin: '',
                as: 'font',
                type: 'font/woff2',
            },
            {
                rel: 'preload',
                href: '/fonts/WOFF2-metric-light.woff2',
                crossorigin: '',
                as: 'font',
                type: 'font/woff2',
            },
            {
                rel: 'preload',
                href: '/fonts/WOFF2-metric-medium.woff2',
                crossorigin: '',
                as: 'font',
                type: 'font/woff2',
            },
            {
                rel: 'preload',
                href: '/fonts/WOFF2-metric-regular.woff2',
                crossorigin: '',
                as: 'font',
                type: 'font/woff2',
            },
            {
                rel: 'preload',
                href: '/fonts/WOFF2-metric-semibold.woff2',
                crossorigin: '',
                as: 'font',
                type: 'font/woff2',
            }
        ];

        return {
            link: [preconnectLink, ...fontLinks],
        };
    });
}

async function clearFormsStore() {
    let keys = Object.keys(formStore.ModalCollection);
    for (let i = 0; i < keys.length; i++) {
        formStore.ModalCollection[keys[i]] = [];
    }
    formStore.CurrentDocuments = [];
}

async function signOutProfile() {
    showProfileDropdown.value = false;
    //loadingRef.value = store.getLoader().value;
    //await loadingRef.value.LoadData(async () => {
    try {
        // Save the current page URL for post logout redirect
        localStorage.setItem('postLogoutRedirect', ensureRelativeURL(currentUriFullPath.value));
        // Make a POST request to the logout endpoint
        const response = await fetch('/api/auth/logout', { method: 'POST' });
        // Check if the request was successful
        if (response.ok) {
            await clearFormsStore();
            // Refresh the sessionId when logging out
            if (!userSession.value?.sessionId || userSession.value?.sessionId !== Guid.Empty.toString()) {
                userSession.value = { sessionId: newGuid().toString() };
                localStorage.setItem('Is_Logged_In', 'false');
                refreshCookie('userSession');
            }

            let notification = {
                type: "success",
                title: "Log out.",
                body: `You have successfully logged out of your account.`,
                date: new Date(),
                timeout: true
            };
            try {
                store.addNotification(notification);
                sessionRefreshed.value++;
                toggleProfileAndSelect();
                await checkLogoutRedirect();
            } catch (error) {
                const currentUrl = window.location.href;
                const logoutUrl = currentUrl.includes('?') ? `${currentUrl}&logout=true` : `${currentUrl}?logout=true`;
                await router.push(ensureRelativeURL(logoutUrl))
                    .catch(error => {
                        console.error('Error during route navigation:', error, 'logoutUrl:', ensureRelativeURL(logoutUrl));
                    });
            }
        } else {
            throw new Error('Logout failed');
        }
    } catch (error) {
        let notification = {
            type: "error",
            title: "Log out failure.",
            body: `We were unable to log out of your account.`,
            date: new Date(),
            timeout: true,
            timer: 10000
        };
        sessionRefreshed.value++;
        store.addNotification(notification);
    }
    //});

    async function checkLogoutRedirect() {
        const postLogoutRedirectUrl = (localStorage.getItem('postLogoutRedirect') || '/');
        const postLogoutRedirectUrlIsProfilePage = postLogoutRedirectUrl.startsWith('/profile/');
        const postLogoutRedirectUrlIsProgrammeApplication = postLogoutRedirectUrl.startsWith('/apply/');
        await new Promise(resolve => setTimeout(resolve, 500));
        if (postLogoutRedirectUrlIsProfilePage || postLogoutRedirectUrlIsProgrammeApplication) {
            const redirectHomePageUri = window.location.origin; // default redirect uri to home page
            // Save the current page uri in local storage for the next login
            localStorage.setItem(SessionKeys.PostLoginRedirectUrl, ensureRelativeURL(postLogoutRedirectUrl));
            // Redirect to the default redirectUri above
            // await router.push(ensureRelativeURL(redirectHomePageUri))
            //     .catch(error => {
            //         console.error('Error during route navigation:', error, 'redirectHomePageUri:', ensureRelativeURL(redirectHomePageUri));
            //     });

            // window.location.href = redirectHomePageUri;
            navigateTo(redirectHomePageUri);
        } else {
            localStorage.removeItem('postLogoutRedirectUrl');
            await router.push(ensureRelativeURL(postLogoutRedirectUrl))
                .catch(error => {
                    console.error('Error during route navigation:', error, 'postLogoutRedirectUrl:', ensureRelativeURL(postLogoutRedirectUrl));
                });
        }
    }
}

await getMainMenuNavigationItems();
await getSecondaryNavigationItems();

if (Array.isArray(secNavItemsResp)) {
    secNavItemsResp.forEach((el: ISecondaryNavigationQuery) => {
        const obj: ISecondaryNavigation = {
            name: el.Name,
            url: el.URL,
            target: el.Target,
            items: el.Items ? transformItems(el.Items) : null,
            expanded: el.expanded
        };
        navItems.push(obj);
    });
}

// watcher: whenever the view changes, collapses all collapsable booleans and elements.
watch(() => props.layoutView.view, () => {
    showLoginForm.value = false;
    showProfileDropdown.value = false;
    showMobileMenu.value = false;
    isMobileSecondaryMenuOpen.value = false;
    isDesktopSecondaryMenuOpen.value = false;
    toggleMobileMenuStyling(showMobileMenu.value);
    toggleMobileSecMenuStyling(isMobileSecondaryMenuOpen.value);
    toggleDesktopSecMenuStyling(isDesktopSecondaryMenuOpen.value);
    nextTick(()=> {
        setMenuAsActive();
    })
});

watch(() => fpStore.getDisplayForgotPassword, () => {
    if (fpStore.getDisplayForgotPassword === true) {
        // Emits from the profile change my password tab forgot password button;
        if (props.layoutView.view === "mobile") {
            isHamburgerMenuOpen.value = true;
            showMobileMenu.value = true;
            toggleMobileMenuStyling(showMobileMenu.value);
            showLoginForm.value = true;
            showForgotPasswordForm.value = true;
        } else {
            showLoginForm.value = true;
            showForgotPasswordForm.value = true;
        }
    }
});

// watch if add form (qualification, profession, etc) button is pressed
watch(() => addFormModalObj.value.isOpen, () => {
    isAddFormModalOpen.value = addFormModalObj.value.isOpen;
})

// watch if the video button is being pressed
watch(() => videoModalObj.value.isOpen, () => {
    if (videoModalObj.value.isOpen === true) {
        isVideoModalOpen.value = true;
        setupsrcUrl();
    }
})

// watch if the video url has been set in the store from application info
watch(() => videoModalObj.value.src, () => {
    videoType.value = videoStore.getVideoModal.type.toLowerCase();
    if (videoType.value === "youtube") {
        setupsrcUrl();
    } else if (videoType.value === 'vimeo') {
        // need the vimeo ID, so breaking the link down to extract the ID only
        const firstPart = videoStore.getVideoModal.src.split('?')[0].split("/");
        renderKey.value = renderKey.value + 1;
        videoUrl.value = firstPart[firstPart.length - 1];
    }
})

watch(() => shareProgStore.getProgShareModal, (newVal) => {
    isShareFormModalOpen.value = newVal; 
});

watch(() => applicationClosedStore.getApplicationClosedModal, (newVal) => {
    isApplicationClosedModalOpen.value = newVal; 
});

watch(() => abortEnrolmentStore.getAbortEnrolmentModal, (newVal) => {
    isAbortEnrolmentModalOpen.value = newVal; 
});

watch(() => formOnlinePaymentStore.getFormPaymentModal, (newVal) => {
    isOnlinePaymentModalOpen.value = newVal; 
});

const loadingState = ref(false);

function LoadingStateChange(currentLoadingState:boolean){
    loadingState.value = currentLoadingState
}

formStore.setLoader(loadingRef); 



</script>

<style>
.grecaptcha-badge {
	z-index: 5;
}

.menu-enter-active,
.menu-leave-active {
    transform: scaleY(1);
    transition: all 0.2s ease;
}

.menu-enter-from,
.menu-leave-to {
    transform: scaleY(0);
}

.menu-list-enter-active,
.menu-list-leave-active {
    max-height: 1200px;
    overflow: hidden;
    transition: all 0.2s ease;
}

.menu-list-enter-from,
.menu-list-leave-to {
    overflow: hidden;
    max-height: 0px;
}

.rolldown-enter-active,
.rolldown-leave-active {
    max-height: 600px;
    overflow: hidden;
    transition: all 0.2s ease;
}

.rolldown-enter-from,
.rolldown-leave-to {
    overflow: hidden;
    max-height: 0px;
}

.modal-enter-active,
.modal-leave-active {
    transition: opacity 0.2s ease;
}

.modal-enter-from,
.modal-leave-to {
    opacity: 0;
}
</style>