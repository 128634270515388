import { default as _91_46_46_46slug_932lj4YbwrCPMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/[...slug].vue?macro=true";
import { default as _404CGGOgo5WcdMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/404.vue?macro=true";
import { default as indexItpoxgrxQPMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/a-solution-for-your-organisation/index.vue?macro=true";
import { default as _91tab_932Rk4F9ixHlMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/account/[tab].vue?macro=true";
import { default as index1zefhzGBA9Meta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/account/index.vue?macro=true";
import { default as _91form_93XO32m7nooVMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/apply/[form].vue?macro=true";
import { default as completeJMrdgfYWOlMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/apply/complete.vue?macro=true";
import { default as indexFHZsxE3Wc8Meta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/azure/index.vue?macro=true";
import { default as index1agIkWiQ3XMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/coaching-listing/index.vue?macro=true";
import { default as indexgwpmdHWTOnMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/content/index.vue?macro=true";
import { default as indexn72iBSYCSxMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/course-finder/index.vue?macro=true";
import { default as indexdLRAQTdLsVMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/course-finder/results/index.vue?macro=true";
import { default as _91tab_936eqeJaqwzVMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/course-listing/[tab].vue?macro=true";
import { default as _91tab_93Kkg7U0v8RRMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/faculty/[tab].vue?macro=true";
import { default as indexdtyZgV2pPvMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/faculty/index.vue?macro=true";
import { default as indexMMFtfQaSXxMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/human-acumen-booklet/index.vue?macro=true";
import { default as indexUBlGzbKc34Meta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/index.vue?macro=true";
import { default as indexTQSMqKjtPVMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/info-central/index.vue?macro=true";
import { default as indexwSLCkAk4tKMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/legal/index.vue?macro=true";
import { default as indexXhljvtSNqFMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/news/index.vue?macro=true";
import { default as confirmationBWG59NvUQOMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/payment/confirmation.vue?macro=true";
import { default as indexoMmAHNal2dMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/payment/index.vue?macro=true";
import { default as indexcwwV9ot7viMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/preview/index.vue?macro=true";
import { default as indexNoKU8fG3CbMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/preview/programmes/[tab]/index.vue?macro=true";
import { default as _91tab_93Z8tq50BRU7Meta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/profile/[tab].vue?macro=true";
import { default as indexFFFikYusQhMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/profile/index.vue?macro=true";
import { default as indexR5EFBiCKfqMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/programmes/[tab]/index.vue?macro=true";
import { default as reset_45passwordQn4TBbFfNfMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/reset-password.vue?macro=true";
import { default as font_45testB7jqcNmLAQMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/sample/font-test.vue?macro=true";
import { default as indexBRgJRchuvwMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/sample/index.vue?macro=true";
import { default as notificationVy0EtblE27Meta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/sample/notification.vue?macro=true";
import { default as programmesTestWFsc4NWr5sMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/sample/programmesTest.vue?macro=true";
import { default as indexTgugiterZwMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/search/index.vue?macro=true";
import { default as index8rC6FaE2TLMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/settings/index.vue?macro=true";
import { default as indexCSrUs3PgABMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/sitemap/index.vue?macro=true";
import { default as user_45verificationaK51i8b3REMeta } from "C:/agent-gibs/_work/4/s/frontend/web/src/pages/user-verification.vue?macro=true";
export default [
  {
    name: _91_46_46_46slug_932lj4YbwrCPMeta?.name ?? "slug",
    path: _91_46_46_46slug_932lj4YbwrCPMeta?.path ?? "/:slug(.*)*",
    meta: _91_46_46_46slug_932lj4YbwrCPMeta || {},
    alias: _91_46_46_46slug_932lj4YbwrCPMeta?.alias || [],
    redirect: _91_46_46_46slug_932lj4YbwrCPMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/[...slug].vue").then(m => m.default || m)
  },
  {
    name: _404CGGOgo5WcdMeta?.name ?? "404",
    path: _404CGGOgo5WcdMeta?.path ?? "/404",
    meta: _404CGGOgo5WcdMeta || {},
    alias: _404CGGOgo5WcdMeta?.alias || [],
    redirect: _404CGGOgo5WcdMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/404.vue").then(m => m.default || m)
  },
  {
    name: indexItpoxgrxQPMeta?.name ?? "a-solution-for-your-organisation",
    path: indexItpoxgrxQPMeta?.path ?? "/a-solution-for-your-organisation",
    meta: indexItpoxgrxQPMeta || {},
    alias: indexItpoxgrxQPMeta?.alias || [],
    redirect: indexItpoxgrxQPMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/a-solution-for-your-organisation/index.vue").then(m => m.default || m)
  },
  {
    name: _91tab_932Rk4F9ixHlMeta?.name ?? "account-tab",
    path: _91tab_932Rk4F9ixHlMeta?.path ?? "/account/:tab()",
    meta: _91tab_932Rk4F9ixHlMeta || {},
    alias: _91tab_932Rk4F9ixHlMeta?.alias || [],
    redirect: _91tab_932Rk4F9ixHlMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/account/[tab].vue").then(m => m.default || m)
  },
  {
    name: index1zefhzGBA9Meta?.name ?? "account",
    path: index1zefhzGBA9Meta?.path ?? "/account",
    meta: index1zefhzGBA9Meta || {},
    alias: index1zefhzGBA9Meta?.alias || [],
    redirect: index1zefhzGBA9Meta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: _91form_93XO32m7nooVMeta?.name ?? "apply-form",
    path: _91form_93XO32m7nooVMeta?.path ?? "/apply/:form()",
    meta: _91form_93XO32m7nooVMeta || {},
    alias: _91form_93XO32m7nooVMeta?.alias || [],
    redirect: _91form_93XO32m7nooVMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/apply/[form].vue").then(m => m.default || m)
  },
  {
    name: completeJMrdgfYWOlMeta?.name ?? "apply-complete",
    path: completeJMrdgfYWOlMeta?.path ?? "/apply/complete",
    meta: completeJMrdgfYWOlMeta || {},
    alias: completeJMrdgfYWOlMeta?.alias || [],
    redirect: completeJMrdgfYWOlMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/apply/complete.vue").then(m => m.default || m)
  },
  {
    name: indexFHZsxE3Wc8Meta?.name ?? "azure",
    path: indexFHZsxE3Wc8Meta?.path ?? "/azure",
    meta: indexFHZsxE3Wc8Meta || {},
    alias: indexFHZsxE3Wc8Meta?.alias || [],
    redirect: indexFHZsxE3Wc8Meta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/azure/index.vue").then(m => m.default || m)
  },
  {
    name: index1agIkWiQ3XMeta?.name ?? "coaching-listing",
    path: index1agIkWiQ3XMeta?.path ?? "/coaching-listing",
    meta: index1agIkWiQ3XMeta || {},
    alias: index1agIkWiQ3XMeta?.alias || [],
    redirect: index1agIkWiQ3XMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/coaching-listing/index.vue").then(m => m.default || m)
  },
  {
    name: indexgwpmdHWTOnMeta?.name ?? "content",
    path: indexgwpmdHWTOnMeta?.path ?? "/content",
    meta: indexgwpmdHWTOnMeta || {},
    alias: indexgwpmdHWTOnMeta?.alias || [],
    redirect: indexgwpmdHWTOnMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/content/index.vue").then(m => m.default || m)
  },
  {
    name: indexn72iBSYCSxMeta?.name ?? "course-finder",
    path: indexn72iBSYCSxMeta?.path ?? "/course-finder",
    meta: indexn72iBSYCSxMeta || {},
    alias: indexn72iBSYCSxMeta?.alias || [],
    redirect: indexn72iBSYCSxMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/course-finder/index.vue").then(m => m.default || m)
  },
  {
    name: indexdLRAQTdLsVMeta?.name ?? "course-finder-results",
    path: indexdLRAQTdLsVMeta?.path ?? "/course-finder/results",
    meta: indexdLRAQTdLsVMeta || {},
    alias: indexdLRAQTdLsVMeta?.alias || [],
    redirect: indexdLRAQTdLsVMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/course-finder/results/index.vue").then(m => m.default || m)
  },
  {
    name: _91tab_936eqeJaqwzVMeta?.name ?? "course-listing-tab",
    path: _91tab_936eqeJaqwzVMeta?.path ?? "/course-listing/:tab()",
    meta: _91tab_936eqeJaqwzVMeta || {},
    alias: _91tab_936eqeJaqwzVMeta?.alias || [],
    redirect: _91tab_936eqeJaqwzVMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/course-listing/[tab].vue").then(m => m.default || m)
  },
  {
    name: _91tab_93Kkg7U0v8RRMeta?.name ?? "faculty-tab",
    path: _91tab_93Kkg7U0v8RRMeta?.path ?? "/faculty/:tab()",
    meta: _91tab_93Kkg7U0v8RRMeta || {},
    alias: _91tab_93Kkg7U0v8RRMeta?.alias || [],
    redirect: _91tab_93Kkg7U0v8RRMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/faculty/[tab].vue").then(m => m.default || m)
  },
  {
    name: indexdtyZgV2pPvMeta?.name ?? "faculty",
    path: indexdtyZgV2pPvMeta?.path ?? "/faculty",
    meta: indexdtyZgV2pPvMeta || {},
    alias: indexdtyZgV2pPvMeta?.alias || [],
    redirect: indexdtyZgV2pPvMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/faculty/index.vue").then(m => m.default || m)
  },
  {
    name: indexMMFtfQaSXxMeta?.name ?? "human-acumen-booklet",
    path: indexMMFtfQaSXxMeta?.path ?? "/human-acumen-booklet",
    meta: indexMMFtfQaSXxMeta || {},
    alias: indexMMFtfQaSXxMeta?.alias || [],
    redirect: indexMMFtfQaSXxMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/human-acumen-booklet/index.vue").then(m => m.default || m)
  },
  {
    name: indexUBlGzbKc34Meta?.name ?? "index",
    path: indexUBlGzbKc34Meta?.path ?? "/",
    meta: indexUBlGzbKc34Meta || {},
    alias: indexUBlGzbKc34Meta?.alias || [],
    redirect: indexUBlGzbKc34Meta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexTQSMqKjtPVMeta?.name ?? "info-central",
    path: indexTQSMqKjtPVMeta?.path ?? "/info-central",
    meta: indexTQSMqKjtPVMeta || {},
    alias: indexTQSMqKjtPVMeta?.alias || [],
    redirect: indexTQSMqKjtPVMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/info-central/index.vue").then(m => m.default || m)
  },
  {
    name: indexwSLCkAk4tKMeta?.name ?? "legal",
    path: indexwSLCkAk4tKMeta?.path ?? "/legal",
    meta: indexwSLCkAk4tKMeta || {},
    alias: indexwSLCkAk4tKMeta?.alias || [],
    redirect: indexwSLCkAk4tKMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/legal/index.vue").then(m => m.default || m)
  },
  {
    name: indexXhljvtSNqFMeta?.name ?? "news",
    path: indexXhljvtSNqFMeta?.path ?? "/news",
    meta: indexXhljvtSNqFMeta || {},
    alias: indexXhljvtSNqFMeta?.alias || [],
    redirect: indexXhljvtSNqFMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/news/index.vue").then(m => m.default || m)
  },
  {
    name: confirmationBWG59NvUQOMeta?.name ?? "payment-confirmation",
    path: confirmationBWG59NvUQOMeta?.path ?? "/payment/confirmation",
    meta: confirmationBWG59NvUQOMeta || {},
    alias: confirmationBWG59NvUQOMeta?.alias || [],
    redirect: confirmationBWG59NvUQOMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/payment/confirmation.vue").then(m => m.default || m)
  },
  {
    name: indexoMmAHNal2dMeta?.name ?? "payment",
    path: indexoMmAHNal2dMeta?.path ?? "/payment",
    meta: indexoMmAHNal2dMeta || {},
    alias: indexoMmAHNal2dMeta?.alias || [],
    redirect: indexoMmAHNal2dMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/payment/index.vue").then(m => m.default || m)
  },
  {
    name: indexcwwV9ot7viMeta?.name ?? "preview",
    path: indexcwwV9ot7viMeta?.path ?? "/preview",
    meta: indexcwwV9ot7viMeta || {},
    alias: indexcwwV9ot7viMeta?.alias || [],
    redirect: indexcwwV9ot7viMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/preview/index.vue").then(m => m.default || m)
  },
  {
    name: indexNoKU8fG3CbMeta?.name ?? "preview-programmes-tab",
    path: indexNoKU8fG3CbMeta?.path ?? "/preview/programmes/:tab()",
    meta: indexNoKU8fG3CbMeta || {},
    alias: indexNoKU8fG3CbMeta?.alias || [],
    redirect: indexNoKU8fG3CbMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/preview/programmes/[tab]/index.vue").then(m => m.default || m)
  },
  {
    name: _91tab_93Z8tq50BRU7Meta?.name ?? "profile-tab",
    path: _91tab_93Z8tq50BRU7Meta?.path ?? "/profile/:tab()",
    meta: _91tab_93Z8tq50BRU7Meta || {},
    alias: _91tab_93Z8tq50BRU7Meta?.alias || [],
    redirect: _91tab_93Z8tq50BRU7Meta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/profile/[tab].vue").then(m => m.default || m)
  },
  {
    name: indexFFFikYusQhMeta?.name ?? "profile",
    path: indexFFFikYusQhMeta?.path ?? "/profile",
    meta: indexFFFikYusQhMeta || {},
    alias: indexFFFikYusQhMeta?.alias || [],
    redirect: indexFFFikYusQhMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/profile/index.vue").then(m => m.default || m)
  },
  {
    name: indexR5EFBiCKfqMeta?.name ?? "programmes-tab",
    path: indexR5EFBiCKfqMeta?.path ?? "/programmes/:tab()",
    meta: indexR5EFBiCKfqMeta || {},
    alias: indexR5EFBiCKfqMeta?.alias || [],
    redirect: indexR5EFBiCKfqMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/programmes/[tab]/index.vue").then(m => m.default || m)
  },
  {
    name: reset_45passwordQn4TBbFfNfMeta?.name ?? "reset-password",
    path: reset_45passwordQn4TBbFfNfMeta?.path ?? "/reset-password",
    meta: reset_45passwordQn4TBbFfNfMeta || {},
    alias: reset_45passwordQn4TBbFfNfMeta?.alias || [],
    redirect: reset_45passwordQn4TBbFfNfMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/reset-password.vue").then(m => m.default || m)
  },
  {
    name: font_45testB7jqcNmLAQMeta?.name ?? "sample-font-test",
    path: font_45testB7jqcNmLAQMeta?.path ?? "/sample/font-test",
    meta: font_45testB7jqcNmLAQMeta || {},
    alias: font_45testB7jqcNmLAQMeta?.alias || [],
    redirect: font_45testB7jqcNmLAQMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/sample/font-test.vue").then(m => m.default || m)
  },
  {
    name: indexBRgJRchuvwMeta?.name ?? "sample",
    path: indexBRgJRchuvwMeta?.path ?? "/sample",
    meta: indexBRgJRchuvwMeta || {},
    alias: indexBRgJRchuvwMeta?.alias || [],
    redirect: indexBRgJRchuvwMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/sample/index.vue").then(m => m.default || m)
  },
  {
    name: notificationVy0EtblE27Meta?.name ?? "sample-notification",
    path: notificationVy0EtblE27Meta?.path ?? "/sample/notification",
    meta: notificationVy0EtblE27Meta || {},
    alias: notificationVy0EtblE27Meta?.alias || [],
    redirect: notificationVy0EtblE27Meta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/sample/notification.vue").then(m => m.default || m)
  },
  {
    name: programmesTestWFsc4NWr5sMeta?.name ?? "sample-programmesTest",
    path: programmesTestWFsc4NWr5sMeta?.path ?? "/sample/programmesTest",
    meta: programmesTestWFsc4NWr5sMeta || {},
    alias: programmesTestWFsc4NWr5sMeta?.alias || [],
    redirect: programmesTestWFsc4NWr5sMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/sample/programmesTest.vue").then(m => m.default || m)
  },
  {
    name: indexTgugiterZwMeta?.name ?? "search",
    path: indexTgugiterZwMeta?.path ?? "/search",
    meta: indexTgugiterZwMeta || {},
    alias: indexTgugiterZwMeta?.alias || [],
    redirect: indexTgugiterZwMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/search/index.vue").then(m => m.default || m)
  },
  {
    name: index8rC6FaE2TLMeta?.name ?? "settings",
    path: index8rC6FaE2TLMeta?.path ?? "/settings",
    meta: index8rC6FaE2TLMeta || {},
    alias: index8rC6FaE2TLMeta?.alias || [],
    redirect: index8rC6FaE2TLMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/settings/index.vue").then(m => m.default || m)
  },
  {
    name: indexCSrUs3PgABMeta?.name ?? "sitemap",
    path: indexCSrUs3PgABMeta?.path ?? "/sitemap",
    meta: indexCSrUs3PgABMeta || {},
    alias: indexCSrUs3PgABMeta?.alias || [],
    redirect: indexCSrUs3PgABMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/sitemap/index.vue").then(m => m.default || m)
  },
  {
    name: user_45verificationaK51i8b3REMeta?.name ?? "user-verification",
    path: user_45verificationaK51i8b3REMeta?.path ?? "/user-verification",
    meta: user_45verificationaK51i8b3REMeta || {},
    alias: user_45verificationaK51i8b3REMeta?.alias || [],
    redirect: user_45verificationaK51i8b3REMeta?.redirect,
    component: () => import("C:/agent-gibs/_work/4/s/frontend/web/src/pages/user-verification.vue").then(m => m.default || m)
  }
]